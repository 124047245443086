<template>
    <div>
        <vx-card>
            <h1> تعديل اعلانات  {{category.name }}</h1> 
            <div class="mb-5">&#8203;</div>
            <h4 class="mt-8">صور الإعلان</h4>
            <div class="vx-row bottom-seperator">
                <div class="vx-col my-1 w-full mb-6">
                    <div class="con-img-upload flex" id="images" v-if="media.length">
                        <div class="img-upload" v-for="(image, index) in media" :key="index">
                            <button type="button" class="btn-x-file" @click="spliceFile(index, image.id)">
                                <i translate="translate" class="material-icons notranslate">clear</i>
                            </button>
                            <img v-if="image.blob" :src="image.blob" style="max-width: none; max-height: 100%" />
                            <img v-else :src="image.image" style="width: 100%" />
                        </div>
                    </div>
                    <file-upload ref="upload" :multiple="true" v-model="category.banners"
                        class="vs-button bg-primary mt-5" post-action="/post.method" put-action="/put.method"
                        extensions="jpg,gif,png,webp" :maximum="3" @input-filter="inputFilter" @input-file="inputFile">
                        تنزيل صورة
                    </file-upload>
                </div>
            </div>

            <!-- submitting -->
            <div class="vx-row mt-8 btn-alignment">
                <div class="vx-col sm:w-full w-full mb-6">
                    
                    <vs-button class="mr-5 text-lg px-16 py-4" @click="update" color="success" type="filled">حفظ
                    </vs-button>
                </div>
            </div>

        </vx-card>
    </div>
</template>
  
<script>
import VueUploadComponent from 'vue-upload-component'
import { mapActions } from 'vuex'


export default {
    components: {
        "file-upload": VueUploadComponent,
    },
    data() {
        return {
            category: {},
            media: []
        };
    },
    created(){
        this.getData()
    },
    methods: {
        ...mapActions('categories', ['fetchCategory','updateCategory']),
        getData() {
            this.fetchCategory(this.$route.params.id)
                .then(response => {
                    this.category = response.data.category
                    this.media = this.category.banners
                    this.category.deleted_media  =[]
                    this.category.banners  =[]
                })
        },
        inputFile(newFile) {
            this.media.push(newFile);
        },
        inputFilter(newFile, oldFile, prevent) {
            if (newFile.size / 1024.0 > 5000) {
                this.$vs.notify({
                    title: "حدث خطأ",
                    text: "يجب أن لا يكون حجم الصورة أكبر من 5mb",
                    color: "danger",
                });
                return prevent();
            }
            if (newFile && !oldFile) {
                if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
                    this.$vs.notify({
                        title: "حدث خطأ",
                        text: "يجب أن يكون الملف بإمتداد صورة صحيح",
                        color: "danger",
                    });
                    return prevent();
                }
            }
            newFile.blob = "";
            let URL = window.URL || window.webkitURL;
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file);
            }
        },
        spliceFile(index, id) {
            this.category.deleted_media.push(id);
            this.media.splice(index, 1);
        },
        update() {
            let deleted_media = this.category.deleted_media.filter(this.numberOnly);
            var bodyFormData = new FormData();
            deleted_media.forEach((item) => bodyFormData.append("deleted_media[]", item))
            bodyFormData.set("id", this.category.id);
            for (var i = 0; i < this.category.banners.length; i++) {
            
                    let file = this.category.banners[i].file;
                bodyFormData.append("banner[" + i + "]", file);
                
               
            }

            this.updateCategory(bodyFormData)
                .then(() => {
                    this.$vs.notify({
                        title: "تم بنجاح",
                        text: "تم تعديل الاعلانات بنجاح",
                        color: "success",
                    });
                    this.$router.push({ name: "categories" });
                })
                .catch((err) => {

                    this.errors_values = err.response.data.errors;
                    this.$vs.notify({
                        title: "حدث خطأ",
                        text: err.response.data.message,
                        color: "danger",
                    });
                });
        }
    }
}
</script>
  
<style lang="scss">

</style>
  